import { useEffect, useCallback } from 'react';
import { bridge, INativeResponseProps } from '@hanwhalife/bridge';
import { getWebViewUserAgent, isHlpWebview, parseData } from '@hanwhalife/shared-utils';
import { useLoginInfo } from '@hanwhalife/shared-store';
import { CertType, IDeviceInfo, ILegacyLoginBaseInfo, ILoginBaseInfo, ILoginInfoV2 } from '@hanwhalife/ts-types';

// allSettled Polyfill
if (!Promise.allSettled) {
  Promise.allSettled = allSettled;
}

/**
 * 전역 로그인 데이터 세팅(for webview)
 * @param param0
 * @returns
 */
export const useInitializeLoginInfo = ({ initLoad = true }: { initLoad?: boolean }) => {
  const { setLoginInfo } = useLoginInfo();
  const { updateDeviceInfo, updateCertType, handleLegacyAccount, handleOnePassLoginInfo, handleLegacyLoginInfo } =
    useLoginInitializer();

  // 로그인 정보 초기화
  const initializeLoginInfo = useCallback(async () => {
    /**
     * 초기 데이터를 불러온다.
     */
    const initRequests = [
      bridge.isSupport('authCenter'),
      bridge.getDeviceInfo(),
      bridge.loadData({ key: 'certtype' }),
      bridge.loadData({ key: 'account' }),
      bridge.getLoginInfo()
    ];

    const [onePassSupport, deviceInfoRes, certTypeRes, accountRes, loginInfoRes] = await Promise.allSettled(
      initRequests
    );

    const { resData: isSupportYn } = getSettledValue(onePassSupport);
    const isOnePassSupported = isSupportYn === 'Y';

    /**
     * 디바이스 정보 처리
     */
    updateDeviceInfo(getSettledValue(deviceInfoRes).resData as string);

    /**
     * 인증 타입 데이터 처리
     */
    const certTypeData = getSettledValue(certTypeRes).resData as CertType | undefined;
    updateCertType(certTypeData);

    //! TODO: One Pass 강업 시점에 삭제
    if (!isOnePassSupported) {
      const accountData = getSettledValue(accountRes).resData as string | undefined;
      handleLegacyAccount(accountData, certTypeData);
    }

    /**
     * getLoginInfo 처리
     */
    const loginData = getSettledValue(loginInfoRes).resData;
    if (isOnePassSupported) {
      handleOnePassLoginInfo(loginData as string | undefined);
    } else {
      //! TODO: One Pass 강업 시점에 삭제
      handleLegacyLoginInfo(loginData as string | undefined);
    }

    /**
     * 사용자 정보 처리
     */
    setLoginInfo({
      isInitialized: true,
      loginChannel: 'HLAPP',
      platform: getWebViewUserAgent(),
      isNative: isHlpWebview()
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initLoad) {
      initializeLoginInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { initializeLoginInfo };
};

/**
 * LoginInfo V1 -> V2 로 포맷 변환
 * @param legacyLoginInfo
 * @returns
 */
const convertLegacyToLoginInfoV2 = ({
  customerId,
  birthWithResidentRegistrationNumberLastDigit,
  MIN
}: Pick<ILegacyLoginBaseInfo, 'customerId' | 'birthWithResidentRegistrationNumberLastDigit' | 'MIN'>): Pick<
  ILoginBaseInfo,
  'isCustomer' | 'integrationMemberBirthWithResidentRegistrationNumberLastDigit' | 'residentRegistrationNumber'
> => {
  //! customerYn, birthWithResidentRegistrationNumberLastDigit, loginType(v1), isMember(account) 필수변환
  return {
    isCustomer: !!customerId,
    integrationMemberBirthWithResidentRegistrationNumberLastDigit: birthWithResidentRegistrationNumberLastDigit,
    residentRegistrationNumber: MIN
  };
};

/**
 * Settle된 value를 득하는 유틸 함수
 * @param result
 * @returns
 */
const getSettledValue = (result: PromiseSettledResult<INativeResponseProps>) => {
  return result.status === 'fulfilled' ? result.value : result.reason;
};

/**
 * 로그인 관련 데이터 초기화를 위해 제공되는 Hook
 * @returns
 */
export const useLoginInitializer = () => {
  const { setLoginInfo } = useLoginInfo();

  /**
   * App 애서 디바이스 정보를 가져와서 상태를 업데이트 해줍니다.
   * @param deviceData
   */
  const updateDeviceInfo = (deviceData: string) => {
    if (deviceData) {
      const deviceParseData = parseData<IDeviceInfo>(deviceData as string);
      setLoginInfo(deviceParseData);
    }
  };

  /**
   *  App 애서 인증타입을 가져와서 상태를 업데이트 해줍니다.
   * @param certTypeData
   */
  const updateCertType = (certTypeData?: CertType) => {
    if (certTypeData) {
      setLoginInfo({ certType: certTypeData as CertType });
    }
  };

  //! TODO: One Pass 강업 시점에 삭제(for isMember update)
  const handleLegacyAccount = (accountData?: string, certType?: CertType) => {
    if (accountData && certType) {
      setLoginInfo({ isMember: true });
    } else {
      setLoginInfo({ isMember: false });
    }
  };

  /**
   * App 애서 getLoginInfoV2 가져와서 상태를 업데이트 해줍니다.
   * @param loginData
   * @returns
   */
  const handleOnePassLoginInfo = (loginData?: string) => {
    if (!loginData) {
      return;
    }

    const { loginInfo, loginStatus, loginType } = parseData<ILoginInfoV2>(loginData as string);

    setLoginInfo({
      ...(loginInfo ?? {}),
      isMember: loginStatus === 'login' || loginStatus === 'noneLogin',
      customerYn: loginInfo?.isCustomer === true ? 'Y' : 'N',
      loginType,
      isLogined: !!(loginInfo?.accessToken && loginInfo?.integrationMemberName)
    });
  };

  //! TODO: One Pass 강업 시점에 삭제
  const handleLegacyLoginInfo = (loginData?: string) => {
    if (!loginData) {
      return;
    }

    const legacyLoginInfoParseData = parseData<ILegacyLoginBaseInfo>(loginData as string);
    const { birthWithResidentRegistrationNumberLastDigit, customerId, MIN, customerYn } = legacyLoginInfoParseData;

    const convertedLegacyLoginData = convertLegacyToLoginInfoV2({
      birthWithResidentRegistrationNumberLastDigit,
      customerId,
      MIN
    });

    setLoginInfo({
      ...legacyLoginInfoParseData,
      ...convertedLegacyLoginData,
      customerYn,
      isLogined: !!(legacyLoginInfoParseData.accessToken && legacyLoginInfoParseData.integrationMemberName)
    });
  };

  return { updateDeviceInfo, updateCertType, handleLegacyAccount, handleOnePassLoginInfo, handleLegacyLoginInfo };
};

// Promise 결과를 위한 타입 정의
interface PromiseFulfilledResult<T> {
  status: 'fulfilled';
  value: T;
}

interface PromiseRejectedResult<T> {
  status: 'rejected';
  reason: T;
}

type PromiseSettledResult<T> = PromiseFulfilledResult<T> | PromiseRejectedResult<T>;

// allSettled 함수
function allSettled<T>(promises: Array<Promise<T>>): Promise<Array<PromiseSettledResult<T>>> {
  const wrappedPromises = promises.map((p) =>
    Promise.resolve(p).then(
      (value): PromiseSettledResult<T> => ({
        status: 'fulfilled',
        value
      }),
      (reason): PromiseSettledResult<T> => ({
        status: 'rejected',
        reason
      })
    )
  );

  return Promise.all(wrappedPromises);
}
