import { useState, useEffect } from 'react';

/**
 * MSW 설정을 초기화 합니다.
 * @param param0
 * @returns
 */
export const MswInitializer = ({ children, isActive }: { children: React.ReactNode; isActive: boolean }) => {
  // MSW worker가 실행되거나, 필요하지 않을 경우 false
  const [isMswInitializing, setIsMswInitializing] = useState(isActive);

  useEffect(() => {
    const initMocking = async () => {
      const { enableMocking } = await import('@hanwhalife/reactjs/mocks/browser');
      await enableMocking();
      console.log('[MSW] Mocking is Ready>>>>>>>');

      setIsMswInitializing(false);
    };

    if (isMswInitializing) {
      initMocking();
    }
  }, [isMswInitializing]);

  if (isMswInitializing) {
    return null;
  }

  return <>{children}</>;
};
