import { IClientSession } from '@hanwhalife/ts-types';

export const CLIENT_SESSION_MOCK_DATA: Partial<IClientSession> = {
  //! 덮어 쓰고 싶으면 주석 풀고 값을 수정하세요.
  // customerId: '',
  // accessToken: '',
  // refreshToken: '',
  // isInitialized: false,
  // isLogined: false,
  deviceID: 'b561399a866ede63f8a939a3807360a1eaab5a42ac176d7e6f30c3b76a1cd184',
  appVer: '3.0.1',
  modelName: 'SM-N981N',
  platformName: 'iOS',
  platformVersion: '16.2.2',
  carrierName: 'SKT',
  networkType: 'LTE',
  customerMobilePhoneNumber: '0106383wXZy',
  integrationMemberName: '김한화',
  age: 30,
  expiresIn: 43199,
  integrationMemberBirthWithResidentRegistrationNumberLastDigit: '9901081',
  integrationMemberJoinDateTime: '2023-05-11 17:30:30',
  integrationMemberMobilePhoneNumber: '0106383wXZy',
  loginChannel: 'HLAPP',
  loginType: 'EZ_KAKAOV2',
  maskedCustomerMobilePhoneNumber: '0106383****',
  maskedIntegrationMemberEmail: 'vSWr5cZk70Jz47rBONwLa***@naver.com',
  maskedIntegrationMemberMobilePhoneNumber: '0106383****',
  residentRegistrationNumber: '16137fccbd51f3cebb68da1d8eb6bdd1',
  tokenType: 'Bearer',
  certType: 'pin',
  customerYn: 'Y',
  gender: '남성',
  integrationMemberStatus: 'NORM',
  isCustomer: true,
  isIdentityConfirmationTarget: false,
  isMember: true,
  isMobilePhoneNumberMatch: true,
  isNative: true,
  isTruthOrFalsehoodConfirmation: true,
  platform: 'iOS',
  hp_1: '',
  hp_2: '',
  hp_3: '',
  phoneNumber: '',
  MIN: ''
};
