/**
 * 히스토리백 막아야하는 화면들
 */
export const BLOCK_LIST = [
  '/menu/cs-notice',
  '/new-aml/?step=guide',
  '/new-aml/?step=complete',
  // 계약자변경 리스트
  '/change-contractor/apply/?step=1&prevent_backward=true',
  '/change-contractor/apply/?step=7_1',
  '/change-contractor/apply/?step=7_2',
  '/change-contractor/apply/?step=7_3',
  '/change-contractor/apply/?step=processing',
  '/change-contractor/agreement/?step=6',
  '/change-contractor/agreement/?step=7_1',
  '/change-contractor/agreement/?step=7_2',
  // 지대청 블락 리스트
  '/app/designated-agent/agreement/?step=2',
  '/app/designated-agent/agreement/?step=3',
  '/app/designated-agent/agreement/?step=4',
  '/app/designated-agent/application/?step=5',
  '/app/designated-agent/application/?step=5_1',
  '/app/designated-agent/application/?step=5_2',
  '/app/designated-agent/application/?step=5_3',
  // 건강체 블락 리스트
  '/app/change-healthy/?step=3&loading=true',
  '/app/change-healthy/?step=6',
  '/app/change-healthy/?step=7',
  '/app/change-healthy/?step=8',
  // 무사고 블락 리스트
  '/app/no-claim-discount/contractor/?step=7',
  '/app/no-claim-discount/contractor/?step=7_1',
  '/app/no-claim-discount/contractor/?step=7_2',
  '/app/no-claim-discount/insured/?step=3',
  '/app/no-claim-discount/insured/?step=3_1',
  '/app/no-claim-discount/insured/?step=3_2',
  // 청약철회 신청/취소 블락 리스트
  '/app/individual/insurance/cancel-ins/apply/?step=success',
  '/app/individual/insurance/cancel-ins/apply/?step=fail',
  '/app/individual/insurance/cancel-ins/cancel/?step=2',
  '/app/individual/insurance/cancel-ins/cancel/?step=3',
  '/app/individual/insurance/cancel-ins/cancel/?step=3',
  // 운동하는건강보험 보험료 할인신청 완료
  '/app/health-insurance/?step=complete',
  '/menu/privacyleak/?step=complete',
  // 신계약 모니터링 완료
  '/app/individual/monitoring/?step=success',
  '/app/individual/monitoring/?step=fail',
  // hsp 블락 리스트
  '/app/hsp/?step=4'
];
