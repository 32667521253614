import { useState, useEffect } from 'react';
import { useLoginInfo } from '@hanwhalife/shared-store';
import { CLIENT_SESSION_MOCK_DATA } from './login.mock.data';

/**
 * - 권장: 앱 인스펙터로 확인이 불가한 케이스(dev, local)에 사용
 * - 기능: Persist State
 */
export const WebViewDevManager = ({ isActive }: { isActive: boolean }) => {
  const [isDevModeActive, setIsDevModeActive] = useState(false);
  const { loginInfo, setLoginInfo } = useLoginInfo();

  //! 1. 새로고침(시작) > 로컬스토리지에 값이 있는지 확인
  //! 1-1 있으면 > 로컬스토리지 기준으로 전역 데이터 세팅
  //! 1-2 없으면 ? 기초데이타 세팅(mock 기반)
  //! : Dev Mode 준비완료
  useEffect(() => {
    if (isActive) {
      console.log('WebViewDevManager 준비완료!!');

      //! 최초 한번만 실행(기존 로그인 정보가 있으면 state 업데이트)
      const storedLoginInfo = localStorage.getItem('persist-login-info');
      if (storedLoginInfo) {
        try {
          setLoginInfo(JSON.parse(storedLoginInfo));
        } catch (e) {
          alert('로컬스토리지에 저장된 로그인정보 불러오기 실패');
        }
      } else {
        console.log('Init Data>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
        // 최초 (비로그인 기초데이터) 세팅(Mock 기반)
        setLoginInfo({ ...CLIENT_SESSION_MOCK_DATA, isInitialized: true, isLogined: false });
      }

      //! Dev Mode Active
      setIsDevModeActive(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  //! 2. state sync 등록
  useEffect(() => {
    //! localStorage state sync
    if (isDevModeActive) {
      console.log('Persist State Enabled>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');

      localStorage.setItem('persist-login-info', JSON.stringify(loginInfo));
    }
  }, [isDevModeActive, loginInfo]);

  return <></>;
};
