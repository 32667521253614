import { RecoilRoot } from '@hanwhalife/shared-libs/recoil';
import * as Sentry from '@hanwhalife/shared-libs/sentry';
import { QueryClient, QueryClientProvider } from '@hanwhalife/shared-libs/react-query';
import { ReactQueryDevtools } from '@hanwhalife/shared-libs/react-query-devtools';
import { HliThemeProvider, HliGlobalStyle } from '@hanwhalife/design-system';
import { PortalProvider } from '@/components/_shared/context';
import { AppErrorBoundary, ErrorFallback } from '@/components/_shared/boundaries';
import { GlobalUIProvider, HlpGlobalUIs } from '@hanwhalife/reactjs/components/_shared';
import { GlobalStyled } from '@hanwhalife/styled/_shared';
import { Modals } from '@/components/common/modal/Modals';
import { Initialize } from '../init/Initialize';
import { HLI_CDN_WEBFONT_PREFIX, MSW_ENABLED } from '@hanwhalife/constants';
import { UnAuthorizedCheck } from '@hanwhalife/reactjs/components/login-status/post-process/UnAuthorizedCheck';
import { MswInitializer } from '@hanwhalife/reactjs/components/dev/MswInitializer';

const queryClient = new QueryClient();

/**
 * Provider 집합
 * @returns
 */
export const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <MswInitializer isActive={MSW_ENABLED}>
      <HliThemeProvider>
        <HliGlobalStyle fontBaseUrl={`${HLI_CDN_WEBFONT_PREFIX}`} />
        <GlobalStyled />
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <AppErrorBoundary
              FallbackComponent={() => <ErrorFallback />}
              onError={(error) => {
                Sentry.captureException(error);
              }}
            >
              <PortalProvider>
                <GlobalUIProvider>
                  <Initialize />
                  <HlpGlobalUIs />
                  <UnAuthorizedCheck />
                  {children}

                  {/* 사용여부 확인 필요 */}
                  <Modals />
                </GlobalUIProvider>
              </PortalProvider>
            </AppErrorBoundary>
            <ReactQueryDevtools initialIsOpen={false} />
            <div id="_important-modal" style={{ zIndex: 9999 }} className="p-relative" />
          </QueryClientProvider>
        </RecoilRoot>
      </HliThemeProvider>
    </MswInitializer>
  );
};
