import { Spinner } from '@hanwhalife/shared-libs/design-system';
import { usePortalStore } from '@/store/portal/portalStore';
import {
  useInitializeLoginInfo,
  useInitAppWebInterfaceFunctions,
  useInitializeCrossBrowser,
  useSetHistoryBlockData,
  useInitializeSentryConfig
} from '@hanwhalife/reactjs/hooks/init';
import { useLoginInfo } from '@hanwhalife/shared-store';
import { useQueryClient } from '@hanwhalife/shared-libs/react-query';
import { MOCK_WEBVIEW_PLUGIN_ENABLED, WEBVIEW_DEV_MANAGER_ENABLED } from '@hanwhalife/constants';
import { WebViewDevManager } from '@hanwhalife/reactjs/components/dev/WebViewDevManager';

/**
 * 초기화할 것들 집합
 * @returns
 */
export const Initialize = () => {
  const { loginInfo } = useLoginInfo();
  const { isInitialized } = loginInfo;

  const queryClient = useQueryClient();
  queryClient.setDefaultOptions({
    queries: {
      enabled: isInitialized
    }
  });

  const { spinnerStore } = usePortalStore();

  // 전역 로그인 데이터 세팅(웹뷰 더미 플러그인 개발모드가 아닐떄 활성화)
  useInitializeLoginInfo({ initLoad: !MOCK_WEBVIEW_PLUGIN_ENABLED });

  // App -> Web 호출 함수 등록
  useInitAppWebInterfaceFunctions();

  // 크로스브라우징 처리
  useInitializeCrossBrowser();

  // 센트리 설정
  useInitializeSentryConfig();

  // 히스토리백 막기 초기화
  useSetHistoryBlockData();

  return (
    <>
      <WebViewDevManager isActive={WEBVIEW_DEV_MANAGER_ENABLED} />
      {spinnerStore && <Spinner isSpinning position="fixed-center" />}
    </>
  );
};
