import { CommonPlugin } from '../plugins/common';
import { WalletPlugin } from '../plugins/wallet';

/**
 * 원본 객체를 유지한채 커스텀 함수를 넣으면 커스텀 함수를 실행 후에 결과를 리턴합니다.
 * @param originalPlugin
 * @param mockImplementations
 * @returns
 */
export const createMockPlugins = <T extends object>(originalPlugin: T, mockImplementations: Partial<T> = {}): T => {
  //
  const mockedPlugin = Object.create(Object.getPrototypeOf(originalPlugin));

  Object.keys(originalPlugin).forEach((key) => {
    //
    const methodName = key as keyof T;

    mockedPlugin[methodName] = (...args: any[]) => {
      //
      console.log(`[Mock Plugin]::${key} 호출됨`);

      if (methodName in mockImplementations && typeof mockImplementations[methodName] === 'function') {
        try {
          const mockFn = mockImplementations[methodName] as (...args: any[]) => any;
          const mockResult = mockFn(...args);

          if (mockResult instanceof Promise) {
            return mockResult;
          }

          return Promise.resolve(mockResult);
        } catch (error) {
          return Promise.reject(error);
        }
      }

      return Promise.resolve({
        resCode: '0000',
        resMessage: 'This data is a message provided by the mock plugin',
        resData: 'dummy data'
      });
    };
  });

  return mockedPlugin as T;
};

//! 커스텀이 필요하면 아래 데이터를 수정하세요.
class MockAppBridge {}
Object.assign(
  MockAppBridge.prototype,
  createMockPlugins(CommonPlugin, {
    isSupport() {
      return Promise.resolve({ resCode: '0000', resMessage: 'success', resData: 'Y' });
    },
    loadData({ key }) {
      if (key === 'certtype') {
        return Promise.resolve({ resCode: '0000', resMessage: 'success', resData: 'pin' });
      }
      if (key === 'account') {
        return Promise.resolve({
          resCode: '0000',
          resMessage: 'success',
          resData: {
            login: 'yes',
            name: '김한화',
            nkid: '1234567890',
            lastAccessTime: ''
          }
        });
      }

      return Promise.resolve({
        resCode: '0000',
        resMessage: 'This data is a message provided by the mock plugin',
        resData: 'dummy response'
      });
    }
  })
);
Object.assign(
  MockAppBridge.prototype,
  createMockPlugins(WalletPlugin, {
    getDeviceInfo() {
      return Promise.resolve({
        resCode: '0000',
        resMessage: 'success',
        resData: {
          deviceID: 'b561399a866ede63f8a939a3807360a1eaab5a42ac176d7e6f30c3b76a1cd184',
          appVer: '3.0.1',
          modelName: 'SM-N981N',
          platformName: 'iOS',
          platformVersion: '16.2.2',
          carrierName: 'SKT',
          networkType: 'LTE'
        }
      });
    },
    getLoginInfo() {
      return Promise.resolve({
        resCode: '0000',
        resMessage: 'success',
        resData: {
          loginStatus: 'login',
          loginType: '4',
          loginInfo: {
            // accessToken: '',
            // refreshToken: '',
            // tokenType: 'Bearer',
            integrationMemberName: '가나다',
            integrationMemberMobilePhoneNumber: '0106383wXZy',
            maskedIntegrationMemberMobilePhoneNumber: '0106383****',
            customerMobilePhoneNumber: '0106383wXZy',
            maskedCustomerMobilePhoneNumber: '0106383****',
            integrationMemberBirthWithResidentRegistrationNumberLastDigit: '9901081',
            residentRegistrationNumber: '16137fccbd51f3cebb68da1d8eb6bdd1',
            integrationMemberJoinDateTime: '2023-05-11 17:30:30',
            isCustomer: true,
            isTruthOrFalsehoodConfirmation: true,
            isIdentityCondrlairmationTarget: false,
            integrationMemberStatus: 'NORM',
            gender: '남성',
            expiresIn: 43199,
            age: 30,
            customerId: '1234567890'
          }
        }
      });
    }
  })
);

export { MockAppBridge };
